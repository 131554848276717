import React from 'react'
import { ContentBlock } from '../../ImageAndContentBlock/'
import Leads from '../../Leads';
import './ContentAndForm.scss'

export const ContentAndForm = (props) => {
  const { formShortcode } = props
  return (
    <section className="content-and-form">
      <div className="wrap">
        <ContentBlock 
          props={props}
        />
        {formShortcode && 
          <div className="form-wrapper">
            <Leads id={formShortcode} />
          </div>
        }
      </div>
    </section>
  )
}
