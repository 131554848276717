import React from 'react'
import RenderContent from '../../RenderContent'
import { ConditionalWrapper } from '../../../utils/ConditionalWrapper';
import './ContentBlock.scss'

export const ContentBlock = props => {
  const { textAlignment, sectionId } = props
  return (
    <section className={`content-block ${textAlignment ? `content-${textAlignment}` : ''}`} id={`${sectionId ? sectionId : ''}`}>
      <div className="wrap">
        <ContentArea {...props} />
      </div>
    </section>
  )
}

const ContentArea = props => {
  const { content, title, subTitle, titleSelectorElement } = props
  return (
    <div className="content-area">
      <ConditionalWrapper
        condition={titleSelectorElement}
        wrapper={children => {
          switch (titleSelectorElement) {
            case 'span': return (
              <span className="content-title">{children}</span>
            )
            case 'h1':
              return <h1 className="content-title">{children}</h1>
            case 'h2':
              return <h2 className="content-title">{children}</h2>
            case 'h3':
              return <h3 className="content-title">{children}</h3>
            default:
              return <span className="content-title">{children}</span>
          }
        }
        }
      >
        {title}{' '}{subTitle && (
          <>
            <br />
            <span className="subtitle">{subTitle}</span>
          </>
        )}
      </ConditionalWrapper>
      {content && <RenderContent content={content} />}
    </div>
  )
}