import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import './Carousel.scss';

export default class Carousel extends Component {
  // Disable vertical scroll while scrolling horizontally solution:
  // https://github.com/akiran/react-slick/issues/1240#issuecomment-396484553
  componentDidMount() {
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart = (e) => {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch = (e) => {
    const minValue = 5; // threshold
    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
    return true;
  }

  render() {
    const {
      // Slick Slider props
      autoplaySpeed = 3000,
      autoplay = false,
      adaptiveHeight = false,
      slidesToShow = 1,
      slidesToScroll = 1,
      arrows = true,
      dots = true,
      fade = false,
      infinite = false,
      responsive,
      rtl = false,
      unslick,
      variableWidth,
      vertical,
      verticalSwiping,
      draggable = true,
      pauseOnHover = true,
      className,
      onInit = () => {},
      getSlick = () => {},
      beforeChange = () => {},
      afterChange = () => {},
      customPaging,
      children,
      centerPadding,
      centerMode,
      dotsClass = 'slick-dots',
    } = this.props;
    // const NextArrow = ({ onClick }) => <div className="slick-arrow slick-next" onClick={onClick}><Arrow /></div>;
    // const PrevArrow = ({ onClick }) => <div className="slick-arrow slick-prev" onClick={onClick}><Arrow /></div>;
    const settings = {
      autoplaySpeed,
      autoplay,
      adaptiveHeight,
      slidesToShow,
      slidesToScroll,
      arrows,
      dots,
      fade,
      infinite,
      responsive,
      rtl,
      unslick,
      variableWidth,
      vertical,
      verticalSwiping,
      draggable,
      pauseOnHover,
      className,
      onInit,
      beforeChange,
      afterChange,
      dotsClass,
      centerPadding,
      centerMode,
      ...(customPaging && {customPaging}),
      onReInit: () => getSlick(this.slider),
      // nextArrow: <NextArrow />,
      // prevArrow: <PrevArrow />
    };
    
    return (
      <Slider {...settings} ref={c => { this.slider = c; }}>
        {children}
      </Slider>
    );
  }
}
