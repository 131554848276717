import React from 'react'
import RenderContent from '../../../RenderContent'
import './ContentBlock.scss'

export const BlogContentBlock = props => {
  const { textAlignment } = props
  return (
    props ? (
      <section className={`content-block ${textAlignment ? `content-${textAlignment}` : ''}`}>
        <div className="wrap">
          <ContentArea {...props} />
        </div>
      </section>
    ) : null
  )
}

const ContentArea = props => {
  const { content, title, subTitle } = props
  if (!content && !title) return null;
  return (
    <div className="content-area">
      <h2>{title}{' '}{subTitle && (
        <>
          <br />
          <span className="subtitle">{subTitle}</span>
        </>
      )}</h2>
      {content && <RenderContent content={content} />}
    </div>
  )
}