import React from 'react'
import RenderContent from '../../../RenderContent';
import { GlobalCTA } from './GlobalCTA/'
import { Image } from '../../../Image'
import LoanMonsterLogo from '../../../SVG/LoanMonsterLogo'
import './BlogCTA.scss'
import '../../CTA/CTA.scss'

export const BlogCTA = (props) => {

  const {
    title,
    subTitle,
    content,
    image,
    showLogo,
    useGlobalSettings,
  } = props

  if (!useGlobalSettings) {
    return (
      <section className="cta-block blog-cta">
        <div className="wrap">
          <div className="title-content-wrapper">
            <div className="cta-title-block">
              <span>
                {title}{' '}{subTitle && <><br /><span className="subtitle">{subTitle}</span></>}
              </span>
            </div>
            <div className="cta-content-block">
              <RenderContent content={content} />
            </div>
            {showLogo &&
              <div className="cta-logo-wrapper">
                <LoanMonsterLogo />
              </div>}
          </div>
          <div className="image-wrapper">
            <Image src={image} />
          </div>
        </div>
      </section>
    )
  } else {
    return <GlobalCTA />
  }
}


