import React from 'react'
import { useMonsterLookingOverFixed } from '../../HOOKS/useMonsterLookingOverFixed'
import RenderContent from '../../RenderContent'
import Img from 'gatsby-image'
import './ComparisonBlock.scss'

export const ComparisonBlock = props => {
  return (
    <section className="comparison-block">
      <div className="wrap">
        <ComparisonContentBlock {...props} />
      </div>
    </section>
  )
}

const ComparisonContentBlock = props => {
  const { title, content, fromRate, comparisonRate } = props
  return (
    <div className="comparison-content-block">
      <div className="comparison-content">
        <h2>{title}<br />{' '}<span>{`${fromRate}%*p.a`} | Comparison{`${comparisonRate}%*p.a`}</span></h2>
        <RenderContent content={content} />
      </div>
      <ComparisonImage fromRate={fromRate} comparisonRate={comparisonRate} />
    </div>
  )
}

const ComparisonImage = props => {
  const { fromRate, comparisonRate } = props
  const image = useMonsterLookingOverFixed()
  return (
    <div className="comparison-image">
      <div className="image-inner">
        <div className="image-wrapper">
          <Img className="monster" fixed={image} />
        </div>
        <div className="board">
          <div className="inner-text-wrapper">
            <div className="from-rate">
              <div className="text-line">
                <span>Starting from</span>
              </div>
              <div className="number-percentage-wrapper">
                <div className="number-val">
                  <span>{fromRate}</span>
                </div>
                <div className="percentage">
                  <span>
                    <span className="pct-symbol">%</span><span className="asterisk">*</span><br />p.a
                  </span>
                </div>
              </div>
            </div>
            <div className="comparison-rate">
              <div className="text-line">
                <span>Comparison</span>
              </div>
              <div className="number-percentage-wrapper">
                <div className="number-val">
                  <span>{comparisonRate}</span>
                </div>
                <div className="percentage">
                  <span>
                    <span className="pct-symbol">%</span><span className="asterisk">*</span><br />p.a
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}