import React from 'react';
import { ImageAndContentBlock } from '../../ImageAndContentBlock'
import './ImageAndContent.scss';

export const ImageAndContent = (props) => {
  // Destructure a few props that we can apply to the section wrapper element, and spread rest of props through to component
  const { backgroundColor = 'white', imageAccent = false, imageType = 'cover', sectionId } = props
  return (
    <section 
      className={`image-and-content 
      ${backgroundColor ? `theme-${backgroundColor}`: ''}
      ${imageAccent ? ` image-accented`: ''}
      ${imageType ? `bg-${imageType}` : ''}
      `}
      id={`${sectionId ? sectionId : ''}`}
    >
      <div className="wrap">
        <ImageAndContentBlock props={{...props}} />
      </div>
    </section>
  )
}

