import React from 'react'
import { useLocationSettings } from '../../HOOKS/useLocationSettings'
import {decodeEntities } from '../../../utils/helpers'
import './LocationBlock.scss'

export const LocationBlock = (props) => {
  const { showMap } = props
  if (!showMap) return null;

  const locations = useLocationSettings();
  
  return (
    <section className="location-block">
      <div className="wrap">
        <div className="google-image-wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1281.3910213248364!2d115.7453484108576!3d-32.05273661411236!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5f28f9a0aacf230e!2sLoan%20Monster%20-%20Fremantle!5e0!3m2!1sen!2sau!4v1634544729854!5m2!1sen!2sau" width="100%" height="100%"></iframe>
        </div>
        <LocationContent locations={locations} />
      </div>
    </section>
  )
}

const LocationContent = props => {
  const { locations } = props
  const { 
    addressCity, 
    addressDisplay, 
    addressPostCode, 
    addressPostal, 
    addressRegion, 
    addressStreet, 
    email, 
    phone, 
    locationName
  } = locations[0];
  return (
    <div className="location-content-wrapper">
      <span className="title">Loan Monster<span className="sub-title"><br />{locationName}</span></span>
      <span className="address">{addressStreet}<br />{addressCity} {addressRegion} {addressPostal ? addressPostal : '6160'}</span>
      <span className="email">{decodeEntities(email)}</span>
      <span className="phone"><strong>{phone}</strong></span>
  </div> 
  )
}
