import React, { useEffect, useState, useRef, useMemo } from 'react'
import GatsbyLink from '../../GatsbyLink'
import RenderContent from '../../RenderContent'
import Slider from 'react-slick'
import { Image } from '../../Image'

import './LinkList.scss'

const isClient = typeof window !== 'undefined'

export const LinkList = props => {
  const { links, title } = props
  return (
    <section className="link-list">
      <div className="wrap">
        {title && <TitleBlock {...props} />}
        <LinkSlider linkItems={links} />
      </div>
    </section>
  )
}

const TitleBlock = props => {
  const { title, subTitle, content } = props
  return (
    <div className="link-list-title-block">
      {title && (
        <span>
          {title}{' '}
          {subTitle && (
            <>
              <br />
              <span className="subtitle">{subTitle}</span>
            </>
          )}
        </span>
      )}
      {content && <RenderContent className="intro-text" content={content} />}
    </div>
  )
}

const sliderSettings = {
  infinite: false,
  dots: false,
  arrows: false,
  autoplay: false,
  slidesToShow: 4,
  variableWidth: false,
  variableHeight: false,
  responsive: [
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 3,
        dots: true,
      },
    },
    {
      breakpoint: 1060,
      settings: {
        dots: true,
        slidesToShow: 1.8,
        slidesToScroll: 1,
        centerPadding: '20px',
        centerMode: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToShow: 1.2,
        slidesToScroll: 1,
        centerPadding: 0,
        centerMode: true,
      },
    },
  ],
}

const LinkSlider = props => {
  const { linkItems } = props
  /**
   * In our LinkSlider component we want to get the tallest content height on the link list items.
   * We will set the height on render so that all elements are of equal height.
   */

  const [itemRefs, setItemRefs] = useState([])

  const [tallestHeight, setTallestHeight] = useState(0)

  const [dimensions, setDimensions] = React.useState({
    height: isClient && window.innerHeight,
    width: isClient && window.innerWidth,
  })

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: isClient && window.innerHeight,
        width: isClient && window.innerWidth,
      })
      setTallestHeight(0)
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    function setTheTallestHeight() {
      itemRefs.map(refs => {
        if (refs.current.scrollHeight > tallestHeight) {
          setTallestHeight(refs.current.scrollHeight)
        }
      })
    }
    setTheTallestHeight()
  }, [itemRefs, tallestHeight, dimensions])

  return (
    <Slider {...sliderSettings}>
      {linkItems &&
        linkItems.filter(Boolean).map((item, index) => {
          return (
            <LinkItem
              {...item}
              key={item.link}
              tallestHeight={tallestHeight && tallestHeight}
              setItemRefs={setItemRefs}
            />
          )
        })}
    </Slider>
  )
}

const LinkItem = props => {
  const {
    title,
    subTitle,
    description,
    image,
    link,
    tallestHeight,
    setItemRefs,
  } = props
  const linkItemRef = useRef()

  const [itemHeight, setItemHeight] = useState()

  useEffect(() => {
    setItemRefs(refs => refs.concat(linkItemRef))
  }, [linkItemRef])

  useEffect(() => {
    setItemHeight(tallestHeight)
  }, [tallestHeight])

  return (
    <div className={`link-item-wrapper ${link ? ' active-link' : ''}`}>
      <div className="link-item__image">
        <GatsbyLink to={link}>
          <Image alt="" src={image} />
        </GatsbyLink>
      </div>
      <div
        className="link-item__content"
        ref={linkItemRef}
        style={
          tallestHeight
            ? { minHeight: `${itemHeight != 0 && itemHeight}px` }
            : null
        }
      >
        <p className="link-item__content-title">
          {title}{' '}
          {subTitle && (
            <>
              <br />
              <span className="subtitle">{subTitle}</span>
            </>
          )}
        </p>
        <p className="link-item__content-description">{description}</p>
        {link ? (
          <GatsbyLink to={link} className="link-item__cta action">
            {link.title ? link.title : 'View More'}
          </GatsbyLink>
        ) : null}
      </div>
    </div>
  )
}
