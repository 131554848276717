import React from 'react';
import GatsbyLink from '../GatsbyLink';
import './Actions.scss';

export const Actions = ({ actions, className = '' }) => {
  if (!actions || actions.length === 0) return null;
  return (
    <div className={`block-actions ${className}`}>
      {actions.map((action, index) => {
        const { link } = action;
        if (!link) return null;
        return (
          <GatsbyLink key={index} className="action" to={link.url} decode>{link.title}</GatsbyLink>
        );
      })}
    </div>
  );
}