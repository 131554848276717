import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import GatsbyLink from '../../GatsbyLink'
import Slider from 'react-slick'
import { decodeEntities } from '../../../utils/helpers'
import Stars from '../../SVG/Stars'
import './TestimonialSlider.scss'

export const TestimonialItem = props => {
  const { slide, className } = props
  return (
    <div className={`${className}`}>
      <div className="content">
        <div className="title-rating">
          <p>{slide.title}</p>
          <div className={`star-rating stars-${slide.acf.starCount}`}>
            <Stars />
          </div>
        </div>
        {slide.content && (
          <div
            dangerouslySetInnerHTML={{ __html: decodeEntities(slide.content) }}
          />
        )}
        {slide.acf.name && (
          <span className="name">
            <strong>
              {decodeEntities(slide.acf.name)}
              {slide.acf.date ? `, ${slide.acf.date}` : ''}
            </strong>
          </span>
        )}
      </div>
    </div>
  )
}

class ThisSlider extends Component {
  render() {
    const { testimonials } = this.props

    const settings = {
      dots: true,
      arrows: false,
      autoplay: false,
      slidesToShow: 1,
      className: 'center-slide',
      centerPadding: '600px',
      centerMode: true,
      infinite: true,
      speed: 500,
      responsive: [
        {
          breakpoint: 1980,
          settings: {
            slidesToShow: 1,
            centerPadding: '400px',
          },
        },
        {
          breakpoint: 1780,
          settings: {
            slidesToShow: 1,
            centerPadding: '200px',
          },
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },
      ],
    }

    return (
      <div className={'carousel-wrap'}>
        <Slider id={'testimonial-slider'} {...settings}>
          {testimonials.map(
            (slide, slide_count) =>
              slide_count < 6 && (
                <TestimonialItem
                  key={slide_count}
                  slide={slide}
                  className="slide"
                />
              )
          )}
        </Slider>
      </div>
    )
  }
}

export const TestimonialPass = props => {
  const { title, subTitle, data, content, viewMore } = props
  const { allWordpressWpTestimonials } = data
  const { nodes: posts } = allWordpressWpTestimonials
  const [hasMounted, setHasMounted] = React.useState(false)

  // Render double pass to make sure content is appearing once component has mounted.
  // Currently appears to be an issue with React Hydrating the testimonial information in the slider
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }

  return (
    <section className={`testimonial-slider`}>
      <div className="title-content-wrapper">
        <div className="title-wrapper">
          {title && (
            <h3>
              {decodeEntities(title)}{' '}
              {subTitle && (
                <>
                  <br />
                  <span className="subtitle">{subTitle}</span>
                </>
              )}
            </h3>
          )}
        </div>
        {content && (
          <div className="content-block">
            <p>{content}</p>
          </div>
        )}
      </div>
      <ThisSlider testimonials={posts} />
      {viewMore && (
        <GatsbyLink
          to={viewMore}
          aria-label="View more"
          decode={true}
          className="action"
        >
          View more
        </GatsbyLink>
      )}
    </section>
  )
}

export const TestimonialSlider = props => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              slug
              title
              acf {
                name
                date
                starCount
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  )
}
