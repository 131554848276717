import React from 'react';
import RenderContent from '../RenderContent';
import { Image } from '../Image'
import { Actions } from '../Actions'
import { ConditionalWrapper } from '../../utils/ConditionalWrapper'

import './ImageAndContentBlock.scss'

export const ImageAndContentBlock = ({ props }) => {
  const {
    arrangement = 'image-left'
  } = props
  return (
    <div className={`image-content-block ${arrangement && arrangement}`}>
      <ImageBlock props={{ ...props }} />
      <ContentBlock props={{ ...props }} />
    </div>
  )
}

ImageAndContentBlock.defaultProps = {
  arrangement: 'image-left'
}

export const ImageBlock = ({ props }) => {
  const { image } = props
  return (
    <div className="image-block">
      <Image src={image} />
    </div>
  )
}

export const ContentBlock = ({ props }) => {
  const { actions, content, title, subTitle, titleSelectorElement = 'span' } = props || {}
  return (
    <div className="content-block">
      <ConditionalWrapper
        condition={titleSelectorElement}
        className="content-block__title"
        wrapper={children => {
          switch (titleSelectorElement) {
            case 'span': return (
              <span className="content-block__title">{children}</span>
            )
            case 'h1':
              return <h1 className="content-block__title">{children}</h1>
            case 'h2':
              return <h2 className="content-block__title">{children}</h2>
            case 'h3':
              return <h3 className="content-block__title">{children}</h3>
            default:
              return <span className="content-block__title">{children}</span>
          }
        }
        }
      >
        {title}{' '}{subTitle && (
          <>
            <br /><span className="subtitle">{subTitle}</span>
          </>
        )}
      </ConditionalWrapper>
      {content && <RenderContent content={content} />}
      {actions && <Actions actions={actions} />}
    </div>
  )
}

ContentBlock.defaultProps = {
  titleSelectorElement: 'span',
  actions: {},
}