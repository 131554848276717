import { useStaticQuery, graphql } from "gatsby"

export const useLocationSettings = () => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query locationSettings {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                locations {
                  phone
                  locationName
                  googleMap
                  faxNumber
                  email
                  addressStreet
                  addressRegion
                  addressPostal
                  addressPostCode
                  addressDisplay
                  addressCity
                }
              }
            }
          }
        }
      }
    `
  )
  return allWordpressAcfOptions.edges[0].node.options.locations
}