import React from 'react';
import { RelatedPosts } from '../../Posts/Related/RelatedPosts';

export const BlogFeed = (props) => {
  if (props.contentType === 'project') {
    return '<p>Project type in blogfeed Not supported.</p>'
    // return <RelatedProjects {...props} />
  }
  if (props.contentType === 'post') {
    return <RelatedPosts {...props} />
  }
  return null
}
