import React from 'react'
import { Image } from '../../../Image'
import './Header.scss'

export const BlogHeader = (props) => {
  const { title, subTitle, image } = props
  return (
    <div className="blog-header">
      <div className="wrap">
        <div className="title-wrapper">
          {title &&
            <h1>
              {title}{' '}{subTitle && (<><br /><span className="subtitle">{subTitle}</span></>)}
            </h1>
          }
          {image &&
            <div className="image-wrapper">
              <Image src={image} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

BlogHeader.defaultProps = {
  title: 'Default Title',
  subTitle: 'Default SubTitle',
  image: 'https://www.birdbrain.com.au/static/057c239d7f0bf01a134a17d53ebf8332/08276/J003070-Birdbrain-in-2019-Daves_Blog.jpg'
}
