import React from 'react'
import { Image } from '../../Image'
import RenderContent from '../../RenderContent';
import MonsterLookingOver from '../../../images/monsters/monster-looking-over.png'
import './ContactHeader.scss'

export const ContactHeader = (props) => {
  const { title, subTitle, content, showImage } = props
  return (
    <section id="contactHeader" className={`contact-header ${showImage ? 'show-image' : ''}`}>
      <div className="wrap">
        <div className="image-outer">
          <div className="image-wrapper">
            <Image src={MonsterLookingOver} />
          </div>
        </div>
        <div className="content-wrapper">
          <h1>{title}{' '}{subTitle && (<><br /><span className="subtitle">{subTitle}</span></>)}</h1>
          <RenderContent content={content} />
        </div>
      </div>
    </section>
  )
}
