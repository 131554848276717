import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import { getImageUrl } from '../../Image';
import './LogoFeed.scss';

export const LogoFeed = (props) => {
  const { LogoGallery: gallery, title } = props;
  const data = useStaticQuery(graphql`
    query FixedImageQuery {
      allWordpressWpMedia {
        edges {
          node {
            id
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 160, quality: 100 ) {
                  ... GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)
  const mediaEdges = data.allWordpressWpMedia.edges
  
  return (
    <section className="logo-feed">
      <div className="wrap">
        {title && <div className="title"><h3 className="section-title">{title}</h3></div>}
        <div className="logo-wrapper">
          {gallery && gallery.filter(Boolean).map(logo =>
            <LogoImageBlock allWordpressMedia={mediaEdges} logo={logo} key={logo.title} />
          )}
        </div>
      </div>
    </section>
  )
}

const LogoImageBlock = props => {
  const { logo, allWordpressMedia } = props;
  if (!allWordpressMedia) return null;
  let srcUrlPass = logo && getImageUrl(logo)
  
  srcUrlPass = srcUrlPass.replace(process.env.GATSBY_WP_ADDRESS, '')
  
  let image = allWordpressMedia.filter(edge => {
    return edge.node.source_url === srcUrlPass;
  })
  return (
    <div className="image-block" key={logo.title}>
      {image && image.length > 0 && image[0].node ? 
        <Img 
          fluid={image[0].node.localFile.childImageSharp.fluid}
          className="logo-wrapper__image" 
        />
        : <p></p>
      }
    </div>
  )
}

