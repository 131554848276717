import React from 'react'
import RenderContent from '../../RenderContent';
import { Image } from '../../Image'
import LoanMonsterLogo from '../../SVG/LoanMonsterLogo'
import './CTA.scss'

export const CTA = (props) => {
  const {
    title,
    subTitle,
    content,
    image,
    showLogo,
    sectionId
  } = props
  return (
    <section className="cta-block" id={`${sectionId ? sectionId : ''}`}>
      <div className="wrap">
        <div className="title-content-wrapper">
          <div className="cta-title-block">
            <span>
              {title}{' '}{subTitle && <><br /><span className="subtitle">{subTitle}</span></>}
            </span>
          </div>
          <div className="cta-content-block">
            <RenderContent content={content} />
          </div>
          {showLogo &&
            <div className="cta-logo-wrapper">
              <LoanMonsterLogo />
            </div>}
        </div>
        <div className="image-wrapper">
          <Image src={image} />
        </div>
      </div>
    </section>
  )
}
