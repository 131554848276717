import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={116} height={20} viewBox="0 0 116 20" {...props}>
      <title>{"Rating"}</title>
      <g fill="#FFD32E" fillRule="evenodd">
        <path d="M106.634.476l1.905 6.18c.09.288.346.514.634.514h6.159c.646 0 .915.837.39 1.235l-4.978 3.768a.708.708 0 00-.243.775l1.903 6.134c.2.645-.504 1.18-1.027.78l-4.985-3.803a.642.642 0 00-.784 0l-4.985 3.797c-.523.399-1.226-.138-1.026-.782l1.902-6.132a.718.718 0 00-.242-.783l-4.98-3.754c-.524-.398-.255-1.235.391-1.235h6.159c.289 0 .545-.226.634-.514l1.905-6.165c.2-.645 1.069-.66 1.268-.015M82.634.476l1.905 6.18c.09.288.346.514.634.514h6.159c.646 0 .915.837.39 1.235l-4.978 3.768a.708.708 0 00-.243.775l1.903 6.134c.2.645-.504 1.18-1.027.78l-4.985-3.803a.642.642 0 00-.784 0l-4.985 3.797c-.523.399-1.226-.138-1.026-.782l1.902-6.132a.718.718 0 00-.242-.783l-4.98-3.754c-.524-.398-.255-1.235.391-1.235h6.159c.289 0 .545-.226.634-.514L81.366.491c.2-.645 1.069-.66 1.268-.015M58.634.476l1.905 6.18c.09.288.346.514.634.514h6.159c.646 0 .915.837.39 1.235l-4.978 3.768a.708.708 0 00-.243.775l1.903 6.134c.2.645-.504 1.18-1.027.78l-4.985-3.803a.642.642 0 00-.784 0l-4.985 3.797c-.523.399-1.226-.138-1.026-.782l1.902-6.132a.718.718 0 00-.242-.783l-4.98-3.754c-.524-.398-.255-1.235.391-1.235h6.159c.289 0 .545-.226.634-.514L57.366.491c.2-.645 1.069-.66 1.268-.015M34.634.476l1.905 6.18c.09.288.346.514.634.514h6.159c.646 0 .915.837.39 1.235l-4.978 3.768a.708.708 0 00-.243.775l1.903 6.134c.2.645-.504 1.18-1.027.78l-4.985-3.803a.642.642 0 00-.784 0l-4.985 3.797c-.523.399-1.226-.138-1.026-.782l1.902-6.132a.718.718 0 00-.242-.783l-4.98-3.754c-.524-.398-.255-1.235.391-1.235h6.159c.289 0 .545-.226.634-.514L33.366.491c.2-.645 1.069-.66 1.268-.015M12.54 6.656c.089.288.345.514.633.514h6.159c.646 0 .915.837.39 1.235l-4.978 3.768a.708.708 0 00-.243.775l1.903 6.134c.2.645-.504 1.18-1.027.78l-4.985-3.803a.642.642 0 00-.784 0l-4.985 3.797c-.523.399-1.226-.138-1.026-.782l1.902-6.132a.718.718 0 00-.242-.783L.277 8.405C-.247 8.007.022 7.17.668 7.17h6.159c.289 0 .545-.226.634-.514L9.366.491c.2-.645 1.069-.66 1.268-.015l1.905 6.18z" />
      </g>
    </svg>
  )
}

export default SvgComponent
